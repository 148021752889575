import { IAssetEntity } from '@@data/assets';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import * as moment from 'moment';

export type ICommunityContentType =
    "Article" | "DocumentCommunity" | "Vod" | "Podcast" | "Event" | "IWEvent" | "Topic" | "Group" | "Forum";
export type ICommunityEventType = "InwinkEvent" | "Webinar" | "ExternalEvent" | "SmallEvent";

export interface IChannel {
    id: string;
    name: string;
}

export interface IMemberContact {
    member: IMember;
    targetMember: IMember;
    discussionId: string;
}

export interface IMemberContactRequest {
    id: string;
    member: IMember;
    memberId: string;
    targetMember: IMember;
    targetMemberId: string;
    createdAt: string;
    text: string;
    status: string;
}

export interface ICommunityTopic {
    id: string;
    title?: VisualTheme.IAppLabel;
    description?: VisualTheme.IAppLabel;
    thumbnail?: IPicture;
    herobanner?: IPicture;
    $staggerIndex?: any;
    visualConfig?: {
        color: string;
        textColor: string;
    }
}

export interface ICommunityEvent {
    id: string;
    title: string;
    computedUrl: string;
}

export interface ICommunityMemberConfiguration {
    feed: {
        allowPublish: boolean;
        allowComment: boolean;
        validateBeforePublishing: boolean;
    },
    content: {
        allowPublish: boolean;
        articles: boolean;
        docs: boolean;
        events: boolean;
        validateBeforePublishing: boolean;
        vods: boolean;
        podcasts: boolean;
    },
    networking: {
        allowed: boolean;
        allowMemberSearch: boolean;
    },
    isModerator: boolean;
    groups: {
        isModerator: boolean;
    }
}

export interface IMembershipLevel {
    id: string;
    name: string;
    configuration: ICommunityMemberConfiguration;
}

export interface IPicture {
    url: string;
    thumbUrl?: string;
    thumbSmallUrl?: string;
    thumbLargeUrl?: string;
    name?: string;
    type?: string;
}

export interface IImage {
    id: string;
    title: VisualTheme.IAppLabel;
    alttext: VisualTheme.IAppLabel;
    image: IPicture;
    legend: VisualTheme.IAppLabel;
}
export interface IMemberNotificationPreferences {
    default: {
        frequency: number,
        optinMailLastContent: boolean
    };
}

export enum notificationFrequency {
    none = 0,
    onTheFly = 1,
    daily = 2,
    weekly = 3
}
export interface IMember {
    id: string;
    firstname: string;
    lastname: string;
    description?: VisualTheme.IAppLabel;
    jobTitle?: string;
    company?: string;
    companyId?: string;
    email?: string;
    membershipLevel?: IMembershipLevel;
    notificationPreferences?: IMemberNotificationPreferences;
    membershipLevelId?: string;
    membershipExpiresAt?: Date;
    contact?: IMemberContact;
    contactRequest?: IMemberContactRequest;
    photo?: IPicture;
    lastVisit?: string;
    $lastVisit?: moment.Moment;
    groupMembers?: IGroupMember[];
    companyAccounts?: ICompanyAccount[];
    contentOptOuts?: IMemberContentOptOut[];
    receivedRequest?: IRequestItem[];
    sentRequest?: IRequestItem[];
    hasSentContactRequest?: boolean;
    hasReceiveContactRequest?: boolean;
    inwinkcustomercontact?: {
        contactEvents?: { eventId: string; isRegistered: boolean; }[]
    };
}

export interface IRequestItem {
    id: string;
    status: string
    text: string;
    createdAt: string;
    updatedAt: string;
    memberId: string;
    targetMemberId: string;
    communityId: string;
    companyWorkspaceId?: string;
    inwinkcustomercontact?: {
        contactEvents?: { eventId: string; isRegistered: boolean; }[]
    };
}

export function getMemberDisplayName(member: IMember) {
    return ((member.firstname || "") + " " + (member.lastname || "")).trim();
}

export function hasValidMembershipLevel(member: IMember): { hasMembership: boolean, membershipIsValid: boolean } {
    const hasMembership = member?.membershipLevel?.id ? true : false;
    const membershipIsValid = member?.membershipExpiresAt
        ? moment(member.membershipExpiresAt).isSameOrAfter(moment().utc(), "day")
        : true;

    return { hasMembership: hasMembership, membershipIsValid: membershipIsValid };
}

export type ICommunityCompanyStatus = "Draft" | "Published" | "Preview";

export interface ICommunityCompany {
    id: string;
    name: string;
    membershipLevel: IMembershipLevel;
    membershipExpiresAt?: Date;
    companyAccounts?: ICompanyAccount[];
    logo?: IPicture;
    email?: string;
    isBookmarked?: boolean;
    topics?: ICommunityTopic[];
    status?: ICommunityCompanyStatus;
}

export interface ICommunityCompanyOffering {
    id: string;
    name: VisualTheme.IAppLabel;
    topics?: ICommunityTopic[];
    description: VisualTheme.IAppLabel;
    companyId?: string;
    illustration?: IPicture;
    url?: VisualTheme.IAppLabel;
    price?: VisualTheme.IAppLabel;
    status?: string;
    documentation?: { [lng: string]: Entities.IExtendedFieldsFile };
    company?: ICommunityCompany;
    isBookmarked?: boolean;
}

export interface ICommunityProduct {
    id: string;
    price: number;
    currency: string;
    vat: number;
    category: string;
    name: VisualTheme.IAppLabel;
    description: VisualTheme.IAppLabel;
    availableStartDate: string;
    availabilityEndDate: string;
    services?: ICommunityProductServices[];
    hasValidationSAS?: boolean;
}

export interface ICommunityProductServices {
    applicationRule: ICommunityProductServicesRules;
    id: string;
    type: string;
}

export interface ICommunityProductServicesRules {
    interval: string;
    intervalCount: number;
    membershipLevelId: string;
}

export interface IGroupInfo {
    id: string;
    groupId: string;
    group: ICommunityGroup;
    views: number;
}
export interface IGroupAccess {
    groupId: string;
    group: ICommunityGroup;
    membershipLevelId: string;
    membershipLevel: IMembershipLevel;
}
export interface IGroupMember {
    groupId: string;
    group: ICommunityGroup;
    memberId: string;
    id: string;
    member: IMember;
    status: string;
    isModerator: boolean;
}

export interface ICompanyAccount {
    companyId: string;
    member: IMember;
    id: string;
    roles: string[];
    updatedUsed?: number;
    updatedRemaining?: number;
}

export interface IMemberContentOptOut {
    id: string;
    memberId: string;
    contentType: string;
    contentId?: string;
}

export interface IGroupInvitationMember {
    groupId: string;
    group: ICommunityGroup;
    memberId: string;
    member: IMember;
}
export interface IGroupModerator {
    groupId: string;
    group: ICommunityGroup;
    memberId: string;
    member: IMember;
}

export interface IGroupTopic {
    groupId: string;
    group: ICommunityGroup;
    topicId: string;
    topic: ICommunityTopic;
}

export type ICommunityGroupStatus = "Draft" | "Published" | "Archived";

export interface ICommunityGroup {
    id: string;
    title: VisualTheme.IAppLabel;
    hideForUnallowed: boolean;
    allowPublicView: boolean;
    hasRestrictedAccess: boolean;
    spotlight: boolean;
    status: ICommunityGroupStatus;
    registrationMode: string;
    groupInfo: IGroupInfo;
    memberAccess: IMembershipLevel[]
    groupModerators: IGroupModerator[];
    moderators: IMember[];
    groupMembers: IGroupMember[];
    members: IMember[];
    groupTopics: IGroupTopic[];
    topics: ICommunityTopic[];
    thumbnail?: IPicture;
    herobanner?: IPicture;
    description?: VisualTheme.IAppLabel;
    company?: ICommunityCompany;
}

export type ICommunityContentStatus = "Draft" | "Published" | "Submitted" | "Archived";
export interface ICommunityContent {
    id: string;
    eventType?: ICommunityEventType;
    publishedAt?: string;
    unpublishedAt?: string;
    title?: VisualTheme.IAppLabel;
    description?: VisualTheme.IAppLabel;
    allowPublicView?: boolean;
    hideForUnallowed?: boolean;
    memberAccess?: IMembershipLevel[];
    memberId?: string;
    status: ICommunityContentStatus;
    isBookmarked?: boolean;
    isLiked?: boolean;
    disableLikes?: boolean;
    isRegistered?: boolean;
    disableComments?: boolean;
    topics: ICommunityTopic[];
    thumbnail?: IPicture;
    herobanner?: IPicture;
    createdAt?: string;
    $createdAt?: moment.Moment;
    $publishedAt?: moment.Moment;
    $unpublishedAt?: moment.Moment;
    info?: {
        likes: number;
        comments: number;
    }
    startDate?: string;
    $startDate?: moment.Moment;
    group: ICommunityGroup[];
    groupId?: string;
    likePreviewOpen?: boolean;
    inwinkEventId?: string;
    inwinkEventRegistrationMode?: "Direct" | "EventWebsite";
    inwinkEventUrl?: string;
}

export interface ICommunityVod extends ICommunityContent {
    videofile?: any;
    videourl?: string;
}

export interface ICommunityTimedContent extends ICommunityContent {
    startDate?: string;
    endDate?: string;
    $startDate?: moment.Moment;
    $endDate?: moment.Moment;
    inwinkEventUrl?: string;
    externalUrl?: string;
}

export interface ICommunityIWEventRegisteredMember {
    isRegistered?: boolean;
}

export interface ICommunityIWEvent extends ICommunityTimedContent {
    template?: Entities.IEntityTemplate;
    formTemplate?: Entities.IExtendedFieldsFormTemplate;
    broadcastUrl: string;
    broadcastId: string;
    archiveId: string;
    // webinarSpeakers: { memberId: string }[];
    speakers: IMember[];
    moderators: IMember[];
    vodId: string;
    vod: ICommunityContent;
    showmessages?: boolean;
    showquestions?: boolean;
    company?: ICommunityCompany;
    contentHtml?: VisualTheme.IAppLabel;
    location?: string;
    allowNonMemberToRegister?: boolean;
}

export interface ICommunityWebinar extends ICommunityIWEvent {
}

export interface ICommunityDiscussionMessage {
    id: string;
    memberId?: string;
    text: string;
    createdAt: string;
    replyTo?: ICommunityDiscussionMessage;
    reactions?: IReaction;
    myReaction?: IMyReaction;
}

export interface ICommunityDiscussionTechnicalMessageText {
    action: ICommunityDiscussionTechnicalMessageTextAction;
    initiator?: IMember;
    target?: IMember;
    info?: string;
}

export type ICommunityDiscussionTechnicalMessageTextAction = "Left" | "Join" | "Rename";

export interface ICommunityDiscussion {
    id: string;
    title?: string;
    isGroup: boolean;
    members: ICommunityDiscussionMember[];
    lastMessageAt?: string;
    lastMessageText?: string;
    createdAt: string;
    isContact?: boolean;
    hasSentContactRequest?: boolean;
    receivedContactRequestId?: string;
}

const communityDiscussionTmpId = "TMP";
export function isTmpDiscussion(discussion: ICommunityDiscussion) {
    return discussion?.id == communityDiscussionTmpId;
}
export function createTmpDiscussion(currentUser: IMember, targetUser: IMember) {
    return {
        id: communityDiscussionTmpId,
        createdAt: "",
        isGroup: false,
        members: [{
            member: currentUser,
            memberId: currentUser.id
        }, {
            member: targetUser,
            memberId: targetUser.id
        }]
    } as ICommunityDiscussion;
}

// eslint-disable-next-line max-len
export type ICommunityNotificationType = "BasicNotification" | "ContactRequestAccepted" | "ContactRequestDeclined" | "NewContactRequest" | "NewDiscussionThreadMessage" | "NewComment" | "NewCommentReply" | "Likes";

export interface ICommunityNotification {
    id: string;
    notificationType: ICommunityNotificationType;
    data: any;
    createdAt: string;
    title: string;
    message: string;
    relatedToKind: string;
    relatedToId: string;
    relatedToParentKind?: string;
    relatedToParentId?: string;
    forumId?: string;
    dismissed: boolean;
    fromMemberId: string;
    groupId?: string;
}

export interface ICommunityDiscussionMember {
    member: IMember;
    memberId: string;
    lastReadAt?: string;
    isAdmin?: boolean;
    discussionThreadId?: string;
    discussionThread?: ICommunityDiscussion;
    isContact?: boolean;
    hasSentContactRequest?: boolean;
    receivedContactRequestId?: string;
}

export type IPublicationView = "Recent" | "Popular";

export type IPublicationStatus = "Draft" | "Submitted" | "Published";

export interface ILink {
    url: string;
    title?: string;
    description?: string;
    imageUrl?: string;
}

export interface IPublication {
    id: string;
    memberId: string;
    year: number;
    likes?: number;
    signals?: number;
    comments?: number;
    text: string;
    status: IPublicationStatus;
    pinned: boolean;
    allowPublicView: boolean;
    disableLikes: boolean;
    disableComments: boolean;
    access: Record<string, boolean>;
    links: ILink[];
    contentId: string;
    contentType: ICommunityContentType;
    hasRestrictedAccess: boolean;
    groupId?: string;
    member?: IMember;
    createdAt: string;
    group?: ICommunityGroup;
    assets?: IPublicationAsset[];
    reactions?: IReaction;
    myReaction?: IMyReaction;
}

export interface IPublicationSignal {
    id: string;
    memberId: string;
    text: string;
    member?: IMember;
    createdAt: string;
}

export interface IReaction {
    likes?: number;
    loves?: number;
    lols?: number;
    surprises?: number;
    sads?: number;
}

export interface IMyReaction {
    likeType: ReactionType;
}

export function itemHasReactions(item: { reactions?: IReaction }): boolean {
    return itemsReactionsTotal(item) > 0;
}

export function itemsReactionsTotal(item: { reactions?: IReaction }): number {
    const reactions = item?.reactions;
    return (reactions?.likes ?? 0) + (reactions?.lols ?? 0) + (reactions?.loves ?? 0)
        + (reactions?.sads ?? 0) + (reactions?.surprises ?? 0);
}

export function incrementReaction(reactions: IReaction, type: ReactionType) {
    switch (type) {
        case ReactionType.Like:
            reactions.likes = (reactions.likes || 0) + 1;
            break;
        case ReactionType.Love:
            reactions.loves = (reactions.loves || 0) + 1;
            break;
        case ReactionType.Lol:
            reactions.lols = (reactions.lols || 0) + 1;
            break;
        case ReactionType.Sad:
            reactions.sads = (reactions.sads || 0) + 1;
            break;
        case ReactionType.Surprise:
            reactions.surprises = (reactions.surprises || 0) + 1;
            break;
    }
}

export function decrementReaction(reactions: IReaction, type: ReactionType) {
    switch (type) {
        case ReactionType.Like:
            reactions.likes = (reactions.likes || 1) - 1;
            if (reactions.likes < 0) reactions.likes = 0;
            break;
        case ReactionType.Love:
            reactions.loves = (reactions.loves || 1) - 1;
            if (reactions.loves < 0) reactions.loves = 0;
            break;
        case ReactionType.Lol:
            reactions.lols = (reactions.lols || 1) - 1;
            if (reactions.lols < 0) reactions.lols = 0;
            break;
        case ReactionType.Sad:
            reactions.sads = (reactions.sads || 1) - 1;
            if (reactions.sads < 0) reactions.sads = 0;
            break;
        case ReactionType.Surprise:
            reactions.surprises = (reactions.surprises || 1) - 1;
            if (reactions.surprises < 0) reactions.surprises = 0;
            break;
    }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ReactionType { Like = "Like", Love = "Love", Lol = "Lol", Surprise = "Surprise", Sad = "Sad" }

export interface IPublicationAsset extends IAssetEntity {

}

export interface IComment {
    id: string;
    text: string;
    memberId: string;
    member?: IMember;
    createdAt: string;
    replies: number;
    likes: number;
    lastReply?: any;
    lastReplyId?: string;
    links: ILink[];
    forumSubjectAssets?: IForumSubjectAsset[];
}
export interface ICommentsDatasource {
    allowEdit(memberId: string): boolean;
    allowDelete(memberId: string): boolean;
    addComment(text: string, links: ILink[], replyTo?: string, groupId?: string): Promise<any>;
    updateComment(commentId: string, text: string, links: ILink[]): Promise<any>;
    removeComment(commentId: string): Promise<any>;
    latestComments(): Promise<{ data: IComment[] }>;
    getComments(
        query: {
            parentId?: string;
            continuationToken?: string
            currentPage?: number;
        }
    ): Promise<{
        data: IComment[],
        continuationToken: string
        totalCount?: number;
        pageSize?: number;
    }>;
    getUrlPreview(url: string): Promise<any>;
    getMembersToMention(query: string, callback: any): void;
    i18nEntityName?: string;
}

export interface ICommunityForum {
    id: string;
    title: VisualTheme.IAppLabel;
    description?: VisualTheme.IAppLabel;
    thumbnail?: IPicture;
    herobanner?: IPicture;
}

export interface ICommunityForumSubject {
    id: string;
    title: string;
    description: string;
    forumId: string;
    memberId: string;
    member?: IMember;
    createdAt: string;
    forumTags?: ICommunityForumTag[];
    info?: {
        replies: number | string;
        likes: number | string;
        lastReply?: string;
        lastReplyMember?: IMember;
    };
    _isFollowed?: boolean;
    _isLiked?: boolean;
    forumSubjectAssets?: IForumSubjectAsset[];
    categorySubjects?: ICommunityForumCategory[];
    categories?: ICommunityForumCategory[];
}

export interface ICommunityForumSubjectReply {
    id: string;
    forumId: string;
    forumSubjectId: string;
    memberId: string;
    member?: IMember;
    info: ICommunityForumSubjectReplyInfo;
    createdAt: string;
    description: string;
}

interface ICommunityForumSubjectReplyInfo {
    likes: number;
}

export interface ICommunityForumTag {
    id: string;
    name: string
}

export interface ICommunityForumCategory {
    id: string;
    title?: VisualTheme.IAppLabel;
    visualConfig?: {
        color: string;
        textColor: string;
    }
}

export interface IForumSubjectAsset {
    name: string;
    value: string;
}