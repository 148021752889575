import * as assign from 'lodash/assign';
import { Entities } from '@inwink/entities/entities';
import { logging } from '@inwink/logging';
import { States } from './services';
import { eventUserBootstrapModule } from '../routes/appmodules';
import * as UXActions from './uxactions';

const logger = logging.getLogger("Data");

const INITIAL_STATE = {
    currentUser: null,
    checked: false
} as States.IAppUserState;

export function userReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "USER_CHECKED": {
            return assign({}, state, {
                checked: true
            });
        }

        case "USER_SETCURRENT": {
            const detail = action.payload.detail as Entities.IAppUser;
            let fullname = "";

            if (detail) {
                if (detail.firstname || detail.lastname) {
                    fullname = ((detail.firstname || "") + " " + (detail.lastname || "")).trim();
                } else if ((detail as any).mail) {
                    fullname = (detail as any).mail;
                } else if ((detail as any).email) {
                    fullname = (detail as any).email;
                } else if (detail.id) {
                    fullname = detail.id;
                }
            }

            return assign({}, state, {
                currentUser: assign({}, state.currentUser, {
                    detail,
                    fullname,
                    member: action.payload.member,
                    data: action.payload.data,
                    stamp: new Date(),
                    isRegistered: (!!action.payload.member?.membershipLevel) || detail?.isRegistered
                })
            });
        }

        case "USER_CURRENTUSERCHANGED": {
            const detail = action.payload.detail;
            let fullname = "";

            if (detail) {
                if (detail.firstname || detail.lastname) {
                    fullname = ((detail.firstname || "") + " " + (detail.lastname || "")).trim();
                } else if ((detail as any).mail) {
                    fullname = (detail as any).mail;
                } else if ((detail as any).email) {
                    fullname = (detail as any).email;
                } else if (detail.id) {
                    fullname = detail.id;
                }
            }

            return assign({}, state, {
                currentUser: assign({}, state.currentUser, {
                    detail,
                    fullname,
                    member: action.payload.member,
                    data: action.payload.data,
                    stamp: new Date(),
                    isRegistered: (!!action.payload.member?.membershipLevel) || detail?.isRegistered
                })
            });
        }

        case "USER_CLEARCURRENT": {
            return assign({}, state, {
                currentUser: null,
                checked: false
            });
        }

        case "USER_DATACHANGED": {
            if (action.payload && action.payload.userData) {
                return assign({}, state, {
                    data: action.payload.userData,
                    currentUser: assign({}, state.currentUser, { stamp: new Date() })
                });
            }
            return assign({}, state, {
                currentUser: assign({}, state.currentUser, { stamp: new Date() })
            });
        }

        case "USER_SYNCREADY": {
            return assign({}, state, {
                isSyncReady: true
            });
        }

        case "USER_MEMBER_CHANGED": {
            return assign({}, state, {
                currentUser: assign({}, state.currentUser, {
                    member: assign({}, state.currentUser.member,
                        action.payload
                    )
                })
            });
        }

        case "USER_GROUPMEMBER_CHANGED": {
            return assign({}, state, {
                currentUser: assign({}, state.currentUser, {
                    member: assign({}, state.currentUser.member,
                        { groupMembers: action.payload.groupMembers }
                    )
                })
            });
        }

        default: {
            return state;
        }
    }
}

export const actions = {
    dataChanged(userData?: States.IPersonDataStore) {
        return (dispatch) => {
            return eventUserBootstrapModule().then((mod) => {
                return mod.currentUserActions.dataChanged(userData)(dispatch);
            });
        };
    }
};

export const favoritesActions = {
    setPreferences(preferences: Entities.IPersonNetworkingPreferences) {
        return (dispatch, getState: () => States.IAppState) => {
            const currentstate = getState() as States.IAppState;
            if (currentstate.user.currentUser.detail) {
                const detailstore = currentstate.user.currentUser.detail;
                detailstore.preferences = preferences;
                actions.dataChanged()(dispatch);
            } else {
                logger.warn("no user data initialized");
                return null;
            }
        };
    }
};

UXActions.registerLogoutCallback((reload, dispatch, getState) => {
    return eventUserBootstrapModule().then((mod) => {
        mod.userBootstrapActions.logout(reload)(dispatch, getState);
    });
});
