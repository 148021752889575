import { Entities } from '@inwink/entities/entities';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { States } from '../services/services';

function getConfigTemplate(data: States.IDataStoreWithTemplates, template: string,
    contentType = 'config'): Entities.IVisualConfigurationTemplate {
    let res = null;
    if (data && data.ready && data.templates) {
        res = data.templates.data.find((t) => t.contentType === contentType && t.tinyUrl === template);
    }
    return res ? res.config : null;
}

function getFullConfigTemplate(data: States.IDataStoreWithTemplates,
    template: string, contentType = 'config'): Entities.IContentTemplate {
    let res = null;
    if (data && data.ready && data.templates) {
        res = data.templates.data.find((t) => 
            t.contentType === contentType && t.tinyUrl === template && t.application !== 'communitygroup'
        );
    }
    return res;
}

export function getVisualConfiguration(data: States.IDataStoreWithTemplates): Entities.IVisualConfigurationTemplate {
    return getConfigTemplate(data, "visualconfig");
}

export function getWebmaster(data: States.IDataStoreWithTemplates): VisualTheme.IWebmasterConfig {
    return getConfigTemplate(data, "webmaster") as any;
}

export function getCopilot(data: States.IDataStoreWithTemplates) {
    return getConfigTemplate(data, "copilot") as any;
}

export function getPageFooter(data: States.IDataStoreWithTemplates): Entities.IContentTemplate {
    return getFullConfigTemplate(data, "pagefooter", "menu") as any;
}

export function getMobileFooter(data: States.IDataStoreWithTemplates): Entities.IContentTemplate {
    return getFullConfigTemplate(data, "mainfooter", "menu") as any;
}

export function getEventTimeline(data: States.IDataStoreWithTemplates): VisualTheme.IContentTemplateConfig {
    return getFullConfigTemplate(data, "maintimeline", "menu") as any;
}

export function getPageHeader(data: States.IDataStoreWithTemplates): Entities.IContentTemplate {
    return getFullConfigTemplate(data, "mainheader", "menu") as any;
}

export function getPageMenu(data: States.IDataStoreWithTemplates): Entities.IContentTemplate {
    return getFullConfigTemplate(data, "mainmenu", "menu") as any;
}

export function getTrads(data: States.IDataStoreWithTemplates): Entities.IVisualConfigurationTemplate {
    return getConfigTemplate(data, "trads");
}

export function getEventAgenda(data: States.IDataStoreWithTemplates, agendaTinyUrl): Entities.IEventAgendaConfiguration {
    return agendaTinyUrl
        ? getConfigTemplate(data, agendaTinyUrl, "eventagenda") as any
        : getConfigTemplate(data, "event-agenda") as any;
}
